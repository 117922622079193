// Max number of values on a "in" query in Firestore
export const FIREBASE_MAX_IN_BATCH_SIZE = 30;

export const CONSTANTS = {
  OFFER: {
    CONTEST: {
      REMAINING_QTY: 9999,
    },
  },
};

export const DEFAULT_OFFER_PRIORITY = 30;

export const DEFAULT_TIMEZONE = {
  utcValue: 'UTC-3',
  value: 'US/Central',
  label: 'Central Time',
};

export const TIMEZONES_VALUES = [
  {
    utcValue: 'UTC-1',
    value: 'US/Pacific',
    label: 'Pacific Time',
  },
  {
    utcValue: 'UTC-2',
    value: 'US/Mountain',
    label: 'Mountain Time',
  },
  {
    utcValue: 'UTC-3',
    value: 'US/Central',
    label: 'Central Time',
  },
  {
    utcValue: 'UTC-4',
    value: 'US/Eastern',
    label: 'Eastern Time',
  },
  {
    utcValue: 'UTC-9',
    value: 'US/Alaska',
    label: 'Alaska Time',
  },
  {
    utcValue: 'UTC-10',
    value: 'US/Hawaii',
    label: 'Hawaii Time',
  },
];

export enum APPS {
  CONNECT_OFFICE,
  CONNECT_BRAND,
  TEAM_MANAGER,
}

export const SELL_SHEET_OFFER_NAME = 'Sell Sheet';

export const OFFER_TYPES_PRETTY = [
  {
    value: 'video',
    display: 'Video',
  },
  {
    value: 'survey',
    display: 'Survey',
  },
  {
    value: 'quiz',
    display: 'Quiz',
  },
  {
    value: 'employeeVerification',
    display: 'Employee Verification',
  },
  // TODO: Uncomment this once makio is running up
  // {
  //   value: 'dollarContest',
  //   display: 'Dollar Contest',
  // },
  // {
  //   value: 'skuContest',
  //   display: 'Per Unit contest',
  // },
  // {
  //   value: 'teamContest',
  //   display: 'Team contest',
  // },
  {
    value: 'carousel',
    display: 'Carousel',
  },
  {
    value: 'video',
    display: 'Video',
  },
  {
    value: 'sellSheet',
    display: SELL_SHEET_OFFER_NAME,
  },
];

export const OFFER_STATUS_PRETTY = [
  {
    value: 'draft',
    display: 'Draft',
  },
  {
    value: 'publish',
    display: 'Publish',
  },
  {
    value: 'active',
    display: 'Active',
  },
  {
    value: 'paused',
    display: 'Paused',
  },
  {
    value: 'archived',
    display: 'Archived',
  },
] as const;

export const STATES = [
  { value: 'AL', display: 'Alabama' },
  { value: 'AK', display: 'Alaska' },
  { value: 'AS', display: 'American Samoa' },
  { value: 'AZ', display: 'Arizona' },
  { value: 'AR', display: 'Arkansas' },
  { value: 'CA', display: 'California' },
  { value: 'CO', display: 'Colorado' },
  { value: 'CT', display: 'Connecticut' },
  { value: 'DE', display: 'Delaware' },
  { value: 'DC', display: 'District Of Columbia' },
  { value: 'FM', display: 'Federated States Of Micronesia' },
  { value: 'FL', display: 'Florida' },
  { value: 'GA', display: 'Georgia' },
  { value: 'GU', display: 'Guam' },
  { value: 'HI', display: 'Hawaii' },
  { value: 'ID', display: 'Idaho' },
  { value: 'IL', display: 'Illinois' },
  { value: 'IN', display: 'Indiana' },
  { value: 'IA', display: 'Iowa' },
  { value: 'KS', display: 'Kansas' },
  { value: 'KY', display: 'Kentucky' },
  { value: 'LA', display: 'Louisiana' },
  { value: 'ME', display: 'Maine' },
  { value: 'MH', display: 'Marshall Islands' },
  { value: 'MD', display: 'Maryland' },
  { value: 'MA', display: 'Massachusetts' },
  { value: 'MI', display: 'Michigan' },
  { value: 'MN', display: 'Minnesota' },
  { value: 'MS', display: 'Mississippi' },
  { value: 'MO', display: 'Missouri' },
  { value: 'MT', display: 'Montana' },
  { value: 'NE', display: 'Nebraska' },
  { value: 'NV', display: 'Nevada' },
  { value: 'NH', display: 'New Hampshire' },
  { value: 'NJ', display: 'New Jersey' },
  { value: 'NM', display: 'New Mexico' },
  { value: 'NY', display: 'New York' },
  { value: 'NC', display: 'North Carolina' },
  { value: 'ND', display: 'North Dakota' },
  { value: 'MP', display: 'Northern Mariana Islands' },
  { value: 'OH', display: 'Ohio' },
  { value: 'OK', display: 'Oklahoma' },
  { value: 'OR', display: 'Oregon' },
  { value: 'PW', display: 'Palau' },
  { value: 'PA', display: 'Pennsylvania' },
  { value: 'PR', display: 'Puerto Rico' },
  { value: 'RI', display: 'Rhode Island' },
  { value: 'SC', display: 'South Carolina' },
  { value: 'SD', display: 'South Dakota' },
  { value: 'TN', display: 'Tennessee' },
  { value: 'TX', display: 'Texas' },
  { value: 'UT', display: 'Utah' },
  { value: 'VT', display: 'Vermont' },
  { value: 'VI', display: 'Virgin Islands' },
  { value: 'VA', display: 'Virginia' },
  { value: 'WA', display: 'Washington' },
  { value: 'WV', display: 'West Virginia' },
  { value: 'WI', display: 'Wisconsin' },
  { value: 'WY', display: 'Wyoming' },
];

export const Platform = 'Cellar'
export const PlatformAndDomain = 'Cellar'
export const PlatformLink = 'cellarco.ai'
